<template>
  <v-text-field
    :key="renderKey"
    :value="computedValue"
    :label="hideLabel ? null : label"
    :loading="loading"
    :error-messages="errors"
    class="rai-time-picker-text"
    :class="computedClass"
    :name="name"
    v-bind="$attrs"
    @blur="parseAndSetValue"
    @keyup.enter="parseAndSetValue"
  />
</template>

<script>
// TODO: Need to figure out how to add this component to a validatable form.

import { format, isDate, isBefore, parseISO, isValid } from "date-fns";
import { parse as timeParse } from "@/utils/timelanguage";
import { debounce } from "lodash";
export default {
  name: "RaiTimePickerText",
  // Required by validatable interface
  inject: {
    form: { default: null },
  },
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Date],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    referenceDate: {
      type: [String, Date],
      default: () => new Date(),
    },
    name: {
      type: String,
      default: "time",
    },
    min: {
      type: [String, Date],
      default: null,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    noShrink: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    errors: [],
    hasError: false,
    renderKey: 0,
  }),
  computed: {
    // The v-model for the text input.
    //
    // The getter will either return
    //   - `h:mma` format of the timestamp
    //   - `Invalid Date` if there is a value but it cannot be parsed
    //   - `null`
    //
    // The setter debounces an emission of the `input` event. The debounce threshold
    // is pretty high, but that's to prevent the time from parsing while the user is typing.
    // A blur event or a `keyup.enter` on the input will immediately emit
    computedValue: {
      get() {
        if (isValid(this.value)) {
          if (typeof this.value === "string") {
            return format(parseISO(this.value), "h:mma");
          } else return format(this.value, "h:mma");
        } else return null;
      },
      set(v) {
        this.emitInput(v);
      },
    },
    computedReferenceDate() {
      return isDate(this.referenceDate)
        ? this.referenceDate
        : parseISO(this.referenceDate);
    },
    computedClass() {
      return [
        this.fullWidth && "full-width",
        this.noShrink && "no-shrink",
      ].filter((e) => e);
    },
  },
  // Required by validatable interface
  created() {
    this.form && this.form.register(this);
  },
  // Required by validatable interface
  beforeDestroy() {
    this.form && this.form.unregister(this);
  },
  methods: {
    format,
    isDate,
    timeParse,
    // - Parse the value
    // - If the value is before the minimum prop, error
    // - If value cannot be parsed, error
    emitInput(v) {
      try {
        const value = timeParse(v, this.computedReferenceDate);
        if (this.min && isBefore(value, this.min)) {
          this.hasError = true;
          throw new Error(`Cannot be before ${format(this.min, "h:mma")}`);
        }
        this.$emit("input", value);
        this.errors = [];
        this.hasError = false;
      } catch (error) {
        this.$emit("update:error", true);
        this.errors = [error.message || "Invalid time"];
        this.hasError = true;
      }
      this.renderKey++;
    },
    // Debounced `emitInput` for making sure value gets parsed
    // without interfering with a user typing
    debouncedEmit: debounce(function (v) {
      if (v === null || v === undefined) return;
      this.emitInput(v);
    }, 1500),
    // Event handler proxy to `emitInput`
    parseAndSetValue({ target: { value } }) {
      this.emitInput(value);
    },
    // Required by validatable interface
    validate(force = false, value) {
      if (!value) return false;
      if (value) {
        if (isBefore(value, this.min)) return false;
        if (!isDate(value)) return false;
      }
      return true;
    },
    // Required by validatable interface
    reset() {
      this.$emit("input", null);
      this.errors = [];
      this.hasError = false;
    },
    // Required by validatable interface
    resetValidation() {
      this.errors = [];
      this.hasError = false;
    },
  },
};
</script>

<style>
.v-text-field.rai-time-picker-text {
  min-width: 8ch;
  max-width: 8ch;
}
.v-text-field.rai-time-picker-text.full-width {
  min-width: 100%;
  max-width: 100%;
}
.v-text-field.rai-time-picker-text.no-shrink {
  min-width: initial;
  max-width: initial;
}
</style>
