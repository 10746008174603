import { parseISO as dateParse } from "date-fns";

// Parse a time string into a date
export const parse = (str, ref = new Date()) => {
  ref = (typeof ref === "string" && dateParse(ref)) || ref;
  return (
    colonString(str, ref) || simpleString(str, ref) || numberString(str, ref)
  );
};

// Must be a string containing ':'
//   ex: '5:32p'
const colonString = (str, ref) => {
  if (!/[:.]/.test(str)) return null;

  str = str.trim();
  const period = parsePeriod(str);
  let [h, m] = parseNumbers(str);

  throwHour(h);
  throwMinute(m);

  h = calcHour(+h, period);
  return setTime(+h, +m, ref);
};

// Must be a string with at least one integer
// and a period, 'a' or 'p'
//   ex: 2a, 9p, etc.
const simpleString = (str, ref) => {
  if (!/[ap]/.test(str)) return null;

  const match = str.match(/(?<h>\d+)\s?(?<p>[ap])/i);
  if (!match || !match.groups) return null;

  let { h, p: period } = match.groups;
  h = calcHour(+h, period);

  throwHour(h);

  return setTime(h, 0, ref);
};

// This assumes only a number is given and the
// number will be the hour.
const numberString = (str, ref) => {
  if (!/\d{1,2}/.test(str)) return null;

  const [h] = parseNumbers(str);
  return setTime(h, 0, ref);
};

const clone = (d) => {
  return new Date(d.toJSON());
};

// Adds 12 to `hour` if `period` === 'p' or returns `hour`
// Returns 0 if the previous calculation yields 24
const calcHour = (hour, period) => {
  if (!period) return hour;

  hour = (period === "p" && hour < 12 && hour + 12) || hour;
  return hour === 24 ? 0 : hour;
};

// Extracts the `[ap]` from `str`
const parsePeriod = (str) => {
  const period = str.match(/[ap]/i);
  return period && period.length > 0 ? period[0].toLowerCase() : null;
};

// Extracts `\d` from `str`
const parseNumbers = (str) => {
  return str
    .split(/\D/)
    .filter((e) => e)
    .map((n) => +n);
};

const setTime = (hour, min, ref) => {
  const returnDate = clone(ref);
  returnDate.setHours(hour, min, 0, 0);
  return returnDate;
};

const throwHour = (hour) => {
  if (hour > 23) throw new Error(`Expected hour to be 0..23. Got ${hour}`);
};
const throwMinute = (min) => {
  if (min > 59) throw new Error(`Expected minute to be 0..59. Got ${min}`);
};
